.card {
/* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    background-color: #ffffff;
    height: 15rem;
    width: 10rem;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
    box-shadow: 0 10px 20px 0 rgba(0,0,0,1);
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
}

/* Add some padding inside the card container */
.container {
    padding: 2px 16px;
    color: #828282;
    font-size: 1rem;
}